/* tslint:disable */
/* eslint-disable */
/**
 * Immutable X API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * Contact: support@immutable.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateTransferRequest } from '../models';
// @ts-ignore
import { CreateTransferRequestV1 } from '../models';
// @ts-ignore
import { CreateTransferResponse } from '../models';
// @ts-ignore
import { CreateTransferResponseV1 } from '../models';
// @ts-ignore
import { GetSignableTransferRequest } from '../models';
// @ts-ignore
import { GetSignableTransferRequestV1 } from '../models';
// @ts-ignore
import { GetSignableTransferResponse } from '../models';
// @ts-ignore
import { GetSignableTransferResponseV1 } from '../models';
// @ts-ignore
import { ListTransfersResponse } from '../models';
// @ts-ignore
import { Transfer } from '../models';
/**
 * TransfersApi - axios parameter creator
 * @export
 */
export const TransfersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new transfer request
         * @summary Creates a transfer of multiple tokens between two parties
         * @param {string} xImxEthAddress eth address
         * @param {string} xImxEthSignature eth signature
         * @param {CreateTransferRequest} createTransferRequestV2 Create transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransfer: async (xImxEthAddress: string, xImxEthSignature: string, createTransferRequestV2: CreateTransferRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xImxEthAddress' is not null or undefined
            assertParamExists('createTransfer', 'xImxEthAddress', xImxEthAddress)
            // verify required parameter 'xImxEthSignature' is not null or undefined
            assertParamExists('createTransfer', 'xImxEthSignature', xImxEthSignature)
            // verify required parameter 'createTransferRequestV2' is not null or undefined
            assertParamExists('createTransfer', 'createTransferRequestV2', createTransferRequestV2)
            const localVarPath = `/v2/transfers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xImxEthAddress != null) {
                localVarHeaderParameter['x-imx-eth-address'] = String(xImxEthAddress);
            }

            if (xImxEthSignature != null) {
                localVarHeaderParameter['x-imx-eth-signature'] = String(xImxEthSignature);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTransferRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new transfer request
         * @summary Creates a transfer of tokens between two parties
         * @param {string} xImxEthAddress eth address
         * @param {string} xImxEthSignature eth signature
         * @param {CreateTransferRequestV1} createTransferRequest Create transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransferV1: async (xImxEthAddress: string, xImxEthSignature: string, createTransferRequest: CreateTransferRequestV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xImxEthAddress' is not null or undefined
            assertParamExists('createTransferV1', 'xImxEthAddress', xImxEthAddress)
            // verify required parameter 'xImxEthSignature' is not null or undefined
            assertParamExists('createTransferV1', 'xImxEthSignature', xImxEthSignature)
            // verify required parameter 'createTransferRequest' is not null or undefined
            assertParamExists('createTransferV1', 'createTransferRequest', createTransferRequest)
            const localVarPath = `/v1/transfers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xImxEthAddress != null) {
                localVarHeaderParameter['x-imx-eth-address'] = String(xImxEthAddress);
            }

            if (xImxEthSignature != null) {
                localVarHeaderParameter['x-imx-eth-signature'] = String(xImxEthSignature);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTransferRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets bulk details of a signable transfer
         * @summary Gets bulk details of a signable transfer
         * @param {GetSignableTransferRequest} getSignableTransferRequestV2 get details of signable transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignableTransfer: async (getSignableTransferRequestV2: GetSignableTransferRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getSignableTransferRequestV2' is not null or undefined
            assertParamExists('getSignableTransfer', 'getSignableTransferRequestV2', getSignableTransferRequestV2)
            const localVarPath = `/v2/signable-transfer-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getSignableTransferRequestV2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets details of a signable transfer
         * @summary Gets details of a signable transfer
         * @param {GetSignableTransferRequestV1} getSignableTransferRequest get details of signable transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignableTransferV1: async (getSignableTransferRequest: GetSignableTransferRequestV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getSignableTransferRequest' is not null or undefined
            assertParamExists('getSignableTransferV1', 'getSignableTransferRequest', getSignableTransferRequest)
            const localVarPath = `/v1/signable-transfer-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getSignableTransferRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get details of a transfer with the given ID
         * @summary Get details of a transfer with the given ID
         * @param {string} id Transfer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfer: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTransfer', 'id', id)
            const localVarPath = `/v1/transfers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of transfers
         * @summary Get a list of transfers
         * @param {number} [pageSize] Page size of the result
         * @param {string} [cursor] Cursor
         * @param {'transaction_id' | 'updated_at' | 'created_at' | 'sender_ether_key' | 'receiver_ether_key'} [orderBy] Property to sort by
         * @param {string} [direction] Direction to sort (asc/desc)
         * @param {string} [user] Ethereum address of the user who submitted this transfer
         * @param {string} [receiver] Ethereum address of the user who received this transfer
         * @param {'success' | 'failure'} [status] Status of this transfer
         * @param {string} [minTimestamp] Minimum timestamp for this transfer, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [maxTimestamp] Maximum timestamp for this transfer, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [tokenType] Token type of the transferred asset
         * @param {string} [tokenId] ERC721 Token ID of the minted asset
         * @param {string} [assetId] Internal IMX ID of the minted asset
         * @param {string} [tokenAddress] Token address of the transferred asset
         * @param {string} [tokenName] Token name of the transferred asset
         * @param {string} [minQuantity] Max quantity for the transferred asset
         * @param {string} [maxQuantity] Max quantity for the transferred asset
         * @param {string} [metadata] JSON-encoded metadata filters for the transferred asset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransfers: async (pageSize?: number, cursor?: string, orderBy?: 'transaction_id' | 'updated_at' | 'created_at' | 'sender_ether_key' | 'receiver_ether_key', direction?: string, user?: string, receiver?: string, status?: 'success' | 'failure', minTimestamp?: string, maxTimestamp?: string, tokenType?: string, tokenId?: string, assetId?: string, tokenAddress?: string, tokenName?: string, minQuantity?: string, maxQuantity?: string, metadata?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/transfers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (receiver !== undefined) {
                localVarQueryParameter['receiver'] = receiver;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (minTimestamp !== undefined) {
                localVarQueryParameter['min_timestamp'] = minTimestamp;
            }

            if (maxTimestamp !== undefined) {
                localVarQueryParameter['max_timestamp'] = maxTimestamp;
            }

            if (tokenType !== undefined) {
                localVarQueryParameter['token_type'] = tokenType;
            }

            if (tokenId !== undefined) {
                localVarQueryParameter['token_id'] = tokenId;
            }

            if (assetId !== undefined) {
                localVarQueryParameter['asset_id'] = assetId;
            }

            if (tokenAddress !== undefined) {
                localVarQueryParameter['token_address'] = tokenAddress;
            }

            if (tokenName !== undefined) {
                localVarQueryParameter['token_name'] = tokenName;
            }

            if (minQuantity !== undefined) {
                localVarQueryParameter['min_quantity'] = minQuantity;
            }

            if (maxQuantity !== undefined) {
                localVarQueryParameter['max_quantity'] = maxQuantity;
            }

            if (metadata !== undefined) {
                localVarQueryParameter['metadata'] = metadata;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransfersApi - functional programming interface
 * @export
 */
export const TransfersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransfersApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new transfer request
         * @summary Creates a transfer of multiple tokens between two parties
         * @param {string} xImxEthAddress eth address
         * @param {string} xImxEthSignature eth signature
         * @param {CreateTransferRequest} createTransferRequestV2 Create transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransfer(xImxEthAddress: string, xImxEthSignature: string, createTransferRequestV2: CreateTransferRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTransferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTransfer(xImxEthAddress, xImxEthSignature, createTransferRequestV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new transfer request
         * @summary Creates a transfer of tokens between two parties
         * @param {string} xImxEthAddress eth address
         * @param {string} xImxEthSignature eth signature
         * @param {CreateTransferRequestV1} createTransferRequest Create transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransferV1(xImxEthAddress: string, xImxEthSignature: string, createTransferRequest: CreateTransferRequestV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTransferResponseV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTransferV1(xImxEthAddress, xImxEthSignature, createTransferRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets bulk details of a signable transfer
         * @summary Gets bulk details of a signable transfer
         * @param {GetSignableTransferRequest} getSignableTransferRequestV2 get details of signable transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignableTransfer(getSignableTransferRequestV2: GetSignableTransferRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSignableTransferResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignableTransfer(getSignableTransferRequestV2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets details of a signable transfer
         * @summary Gets details of a signable transfer
         * @param {GetSignableTransferRequestV1} getSignableTransferRequest get details of signable transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignableTransferV1(getSignableTransferRequest: GetSignableTransferRequestV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSignableTransferResponseV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignableTransferV1(getSignableTransferRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get details of a transfer with the given ID
         * @summary Get details of a transfer with the given ID
         * @param {string} id Transfer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransfer(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transfer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransfer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of transfers
         * @summary Get a list of transfers
         * @param {number} [pageSize] Page size of the result
         * @param {string} [cursor] Cursor
         * @param {'transaction_id' | 'updated_at' | 'created_at' | 'sender_ether_key' | 'receiver_ether_key'} [orderBy] Property to sort by
         * @param {string} [direction] Direction to sort (asc/desc)
         * @param {string} [user] Ethereum address of the user who submitted this transfer
         * @param {string} [receiver] Ethereum address of the user who received this transfer
         * @param {'success' | 'failure'} [status] Status of this transfer
         * @param {string} [minTimestamp] Minimum timestamp for this transfer, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [maxTimestamp] Maximum timestamp for this transfer, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [tokenType] Token type of the transferred asset
         * @param {string} [tokenId] ERC721 Token ID of the minted asset
         * @param {string} [assetId] Internal IMX ID of the minted asset
         * @param {string} [tokenAddress] Token address of the transferred asset
         * @param {string} [tokenName] Token name of the transferred asset
         * @param {string} [minQuantity] Max quantity for the transferred asset
         * @param {string} [maxQuantity] Max quantity for the transferred asset
         * @param {string} [metadata] JSON-encoded metadata filters for the transferred asset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTransfers(pageSize?: number, cursor?: string, orderBy?: 'transaction_id' | 'updated_at' | 'created_at' | 'sender_ether_key' | 'receiver_ether_key', direction?: string, user?: string, receiver?: string, status?: 'success' | 'failure', minTimestamp?: string, maxTimestamp?: string, tokenType?: string, tokenId?: string, assetId?: string, tokenAddress?: string, tokenName?: string, minQuantity?: string, maxQuantity?: string, metadata?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTransfersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTransfers(pageSize, cursor, orderBy, direction, user, receiver, status, minTimestamp, maxTimestamp, tokenType, tokenId, assetId, tokenAddress, tokenName, minQuantity, maxQuantity, metadata, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransfersApi - factory interface
 * @export
 */
export const TransfersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransfersApiFp(configuration)
    return {
        /**
         * Create a new transfer request
         * @summary Creates a transfer of multiple tokens between two parties
         * @param {string} xImxEthAddress eth address
         * @param {string} xImxEthSignature eth signature
         * @param {CreateTransferRequest} createTransferRequestV2 Create transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransfer(xImxEthAddress: string, xImxEthSignature: string, createTransferRequestV2: CreateTransferRequest, options?: any): AxiosPromise<CreateTransferResponse> {
            return localVarFp.createTransfer(xImxEthAddress, xImxEthSignature, createTransferRequestV2, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new transfer request
         * @summary Creates a transfer of tokens between two parties
         * @param {string} xImxEthAddress eth address
         * @param {string} xImxEthSignature eth signature
         * @param {CreateTransferRequestV1} createTransferRequest Create transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransferV1(xImxEthAddress: string, xImxEthSignature: string, createTransferRequest: CreateTransferRequestV1, options?: any): AxiosPromise<CreateTransferResponseV1> {
            return localVarFp.createTransferV1(xImxEthAddress, xImxEthSignature, createTransferRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets bulk details of a signable transfer
         * @summary Gets bulk details of a signable transfer
         * @param {GetSignableTransferRequest} getSignableTransferRequestV2 get details of signable transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignableTransfer(getSignableTransferRequestV2: GetSignableTransferRequest, options?: any): AxiosPromise<GetSignableTransferResponse> {
            return localVarFp.getSignableTransfer(getSignableTransferRequestV2, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets details of a signable transfer
         * @summary Gets details of a signable transfer
         * @param {GetSignableTransferRequestV1} getSignableTransferRequest get details of signable transfer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignableTransferV1(getSignableTransferRequest: GetSignableTransferRequestV1, options?: any): AxiosPromise<GetSignableTransferResponseV1> {
            return localVarFp.getSignableTransferV1(getSignableTransferRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get details of a transfer with the given ID
         * @summary Get details of a transfer with the given ID
         * @param {string} id Transfer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfer(id: string, options?: any): AxiosPromise<Transfer> {
            return localVarFp.getTransfer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of transfers
         * @summary Get a list of transfers
         * @param {number} [pageSize] Page size of the result
         * @param {string} [cursor] Cursor
         * @param {'transaction_id' | 'updated_at' | 'created_at' | 'sender_ether_key' | 'receiver_ether_key'} [orderBy] Property to sort by
         * @param {string} [direction] Direction to sort (asc/desc)
         * @param {string} [user] Ethereum address of the user who submitted this transfer
         * @param {string} [receiver] Ethereum address of the user who received this transfer
         * @param {'success' | 'failure'} [status] Status of this transfer
         * @param {string} [minTimestamp] Minimum timestamp for this transfer, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [maxTimestamp] Maximum timestamp for this transfer, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [tokenType] Token type of the transferred asset
         * @param {string} [tokenId] ERC721 Token ID of the minted asset
         * @param {string} [assetId] Internal IMX ID of the minted asset
         * @param {string} [tokenAddress] Token address of the transferred asset
         * @param {string} [tokenName] Token name of the transferred asset
         * @param {string} [minQuantity] Max quantity for the transferred asset
         * @param {string} [maxQuantity] Max quantity for the transferred asset
         * @param {string} [metadata] JSON-encoded metadata filters for the transferred asset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransfers(pageSize?: number, cursor?: string, orderBy?: 'transaction_id' | 'updated_at' | 'created_at' | 'sender_ether_key' | 'receiver_ether_key', direction?: string, user?: string, receiver?: string, status?: 'success' | 'failure', minTimestamp?: string, maxTimestamp?: string, tokenType?: string, tokenId?: string, assetId?: string, tokenAddress?: string, tokenName?: string, minQuantity?: string, maxQuantity?: string, metadata?: string, options?: any): AxiosPromise<ListTransfersResponse> {
            return localVarFp.listTransfers(pageSize, cursor, orderBy, direction, user, receiver, status, minTimestamp, maxTimestamp, tokenType, tokenId, assetId, tokenAddress, tokenName, minQuantity, maxQuantity, metadata, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTransfer operation in TransfersApi.
 * @export
 * @interface TransfersApiCreateTransferRequest
 */
export interface TransfersApiCreateTransferRequest {
    /**
     * eth address
     * @type {string}
     * @memberof TransfersApiCreateTransfer
     */
    readonly xImxEthAddress: string

    /**
     * eth signature
     * @type {string}
     * @memberof TransfersApiCreateTransfer
     */
    readonly xImxEthSignature: string

    /**
     * Create transfer
     * @type {CreateTransferRequest}
     * @memberof TransfersApiCreateTransfer
     */
    readonly createTransferRequestV2: CreateTransferRequest
}

/**
 * Request parameters for createTransferV1 operation in TransfersApi.
 * @export
 * @interface TransfersApiCreateTransferV1Request
 */
export interface TransfersApiCreateTransferV1Request {
    /**
     * eth address
     * @type {string}
     * @memberof TransfersApiCreateTransferV1
     */
    readonly xImxEthAddress: string

    /**
     * eth signature
     * @type {string}
     * @memberof TransfersApiCreateTransferV1
     */
    readonly xImxEthSignature: string

    /**
     * Create transfer
     * @type {CreateTransferRequestV1}
     * @memberof TransfersApiCreateTransferV1
     */
    readonly createTransferRequest: CreateTransferRequestV1
}

/**
 * Request parameters for getSignableTransfer operation in TransfersApi.
 * @export
 * @interface TransfersApiGetSignableTransferRequest
 */
export interface TransfersApiGetSignableTransferRequest {
    /**
     * get details of signable transfer
     * @type {GetSignableTransferRequest}
     * @memberof TransfersApiGetSignableTransfer
     */
    readonly getSignableTransferRequestV2: GetSignableTransferRequest
}

/**
 * Request parameters for getSignableTransferV1 operation in TransfersApi.
 * @export
 * @interface TransfersApiGetSignableTransferV1Request
 */
export interface TransfersApiGetSignableTransferV1Request {
    /**
     * get details of signable transfer
     * @type {GetSignableTransferRequestV1}
     * @memberof TransfersApiGetSignableTransferV1
     */
    readonly getSignableTransferRequest: GetSignableTransferRequestV1
}

/**
 * Request parameters for getTransfer operation in TransfersApi.
 * @export
 * @interface TransfersApiGetTransferRequest
 */
export interface TransfersApiGetTransferRequest {
    /**
     * Transfer ID
     * @type {string}
     * @memberof TransfersApiGetTransfer
     */
    readonly id: string
}

/**
 * Request parameters for listTransfers operation in TransfersApi.
 * @export
 * @interface TransfersApiListTransfersRequest
 */
export interface TransfersApiListTransfersRequest {
    /**
     * Page size of the result
     * @type {number}
     * @memberof TransfersApiListTransfers
     */
    readonly pageSize?: number

    /**
     * Cursor
     * @type {string}
     * @memberof TransfersApiListTransfers
     */
    readonly cursor?: string

    /**
     * Property to sort by
     * @type {'transaction_id' | 'updated_at' | 'created_at' | 'sender_ether_key' | 'receiver_ether_key'}
     * @memberof TransfersApiListTransfers
     */
    readonly orderBy?: 'transaction_id' | 'updated_at' | 'created_at' | 'sender_ether_key' | 'receiver_ether_key'

    /**
     * Direction to sort (asc/desc)
     * @type {string}
     * @memberof TransfersApiListTransfers
     */
    readonly direction?: string

    /**
     * Ethereum address of the user who submitted this transfer
     * @type {string}
     * @memberof TransfersApiListTransfers
     */
    readonly user?: string

    /**
     * Ethereum address of the user who received this transfer
     * @type {string}
     * @memberof TransfersApiListTransfers
     */
    readonly receiver?: string

    /**
     * Status of this transfer
     * @type {'success' | 'failure'}
     * @memberof TransfersApiListTransfers
     */
    readonly status?: 'success' | 'failure'

    /**
     * Minimum timestamp for this transfer, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
     * @type {string}
     * @memberof TransfersApiListTransfers
     */
    readonly minTimestamp?: string

    /**
     * Maximum timestamp for this transfer, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
     * @type {string}
     * @memberof TransfersApiListTransfers
     */
    readonly maxTimestamp?: string

    /**
     * Token type of the transferred asset
     * @type {string}
     * @memberof TransfersApiListTransfers
     */
    readonly tokenType?: string

    /**
     * ERC721 Token ID of the minted asset
     * @type {string}
     * @memberof TransfersApiListTransfers
     */
    readonly tokenId?: string

    /**
     * Internal IMX ID of the minted asset
     * @type {string}
     * @memberof TransfersApiListTransfers
     */
    readonly assetId?: string

    /**
     * Token address of the transferred asset
     * @type {string}
     * @memberof TransfersApiListTransfers
     */
    readonly tokenAddress?: string

    /**
     * Token name of the transferred asset
     * @type {string}
     * @memberof TransfersApiListTransfers
     */
    readonly tokenName?: string

    /**
     * Max quantity for the transferred asset
     * @type {string}
     * @memberof TransfersApiListTransfers
     */
    readonly minQuantity?: string

    /**
     * Max quantity for the transferred asset
     * @type {string}
     * @memberof TransfersApiListTransfers
     */
    readonly maxQuantity?: string

    /**
     * JSON-encoded metadata filters for the transferred asset
     * @type {string}
     * @memberof TransfersApiListTransfers
     */
    readonly metadata?: string
}

/**
 * TransfersApi - object-oriented interface
 * @export
 * @class TransfersApi
 * @extends {BaseAPI}
 */
export class TransfersApi extends BaseAPI {
    /**
     * Create a new transfer request
     * @summary Creates a transfer of multiple tokens between two parties
     * @param {TransfersApiCreateTransferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransfersApi
     */
    public createTransfer(requestParameters: TransfersApiCreateTransferRequest, options?: AxiosRequestConfig) {
        return TransfersApiFp(this.configuration).createTransfer(requestParameters.xImxEthAddress, requestParameters.xImxEthSignature, requestParameters.createTransferRequestV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new transfer request
     * @summary Creates a transfer of tokens between two parties
     * @param {TransfersApiCreateTransferV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransfersApi
     */
    public createTransferV1(requestParameters: TransfersApiCreateTransferV1Request, options?: AxiosRequestConfig) {
        return TransfersApiFp(this.configuration).createTransferV1(requestParameters.xImxEthAddress, requestParameters.xImxEthSignature, requestParameters.createTransferRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets bulk details of a signable transfer
     * @summary Gets bulk details of a signable transfer
     * @param {TransfersApiGetSignableTransferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransfersApi
     */
    public getSignableTransfer(requestParameters: TransfersApiGetSignableTransferRequest, options?: AxiosRequestConfig) {
        return TransfersApiFp(this.configuration).getSignableTransfer(requestParameters.getSignableTransferRequestV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets details of a signable transfer
     * @summary Gets details of a signable transfer
     * @param {TransfersApiGetSignableTransferV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransfersApi
     */
    public getSignableTransferV1(requestParameters: TransfersApiGetSignableTransferV1Request, options?: AxiosRequestConfig) {
        return TransfersApiFp(this.configuration).getSignableTransferV1(requestParameters.getSignableTransferRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get details of a transfer with the given ID
     * @summary Get details of a transfer with the given ID
     * @param {TransfersApiGetTransferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransfersApi
     */
    public getTransfer(requestParameters: TransfersApiGetTransferRequest, options?: AxiosRequestConfig) {
        return TransfersApiFp(this.configuration).getTransfer(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of transfers
     * @summary Get a list of transfers
     * @param {TransfersApiListTransfersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransfersApi
     */
    public listTransfers(requestParameters: TransfersApiListTransfersRequest = {}, options?: AxiosRequestConfig) {
        return TransfersApiFp(this.configuration).listTransfers(requestParameters.pageSize, requestParameters.cursor, requestParameters.orderBy, requestParameters.direction, requestParameters.user, requestParameters.receiver, requestParameters.status, requestParameters.minTimestamp, requestParameters.maxTimestamp, requestParameters.tokenType, requestParameters.tokenId, requestParameters.assetId, requestParameters.tokenAddress, requestParameters.tokenName, requestParameters.minQuantity, requestParameters.maxQuantity, requestParameters.metadata, options).then((request) => request(this.axios, this.basePath));
    }
}
