/* tslint:disable */
/* eslint-disable */
/**
 * Immutable X API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * Contact: support@immutable.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CancelOrderRequest } from '../models';
// @ts-ignore
import { CancelOrderResponse } from '../models';
// @ts-ignore
import { CreateOrderRequest } from '../models';
// @ts-ignore
import { CreateOrderResponse } from '../models';
// @ts-ignore
import { GetSignableCancelOrderRequest } from '../models';
// @ts-ignore
import { GetSignableCancelOrderResponse } from '../models';
// @ts-ignore
import { GetSignableOrderRequest } from '../models';
// @ts-ignore
import { GetSignableOrderResponse } from '../models';
// @ts-ignore
import { ListOrdersResponse } from '../models';
// @ts-ignore
import { Order } from '../models';
/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Cancel an order
         * @summary Cancel an order
         * @param {string} xImxEthAddress eth address
         * @param {string} xImxEthSignature eth signature
         * @param {string} id Order ID to cancel
         * @param {CancelOrderRequest} cancelOrderRequest cancel an order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrder: async (xImxEthAddress: string, xImxEthSignature: string, id: string, cancelOrderRequest: CancelOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xImxEthAddress' is not null or undefined
            assertParamExists('cancelOrder', 'xImxEthAddress', xImxEthAddress)
            // verify required parameter 'xImxEthSignature' is not null or undefined
            assertParamExists('cancelOrder', 'xImxEthSignature', xImxEthSignature)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelOrder', 'id', id)
            // verify required parameter 'cancelOrderRequest' is not null or undefined
            assertParamExists('cancelOrder', 'cancelOrderRequest', cancelOrderRequest)
            const localVarPath = `/v1/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xImxEthAddress != null) {
                localVarHeaderParameter['x-imx-eth-address'] = String(xImxEthAddress);
            }

            if (xImxEthSignature != null) {
                localVarHeaderParameter['x-imx-eth-signature'] = String(xImxEthSignature);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an order
         * @summary Create an order
         * @param {string} xImxEthAddress eth address
         * @param {string} xImxEthSignature eth signature
         * @param {CreateOrderRequest} createOrderRequest create an order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder: async (xImxEthAddress: string, xImxEthSignature: string, createOrderRequest: CreateOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xImxEthAddress' is not null or undefined
            assertParamExists('createOrder', 'xImxEthAddress', xImxEthAddress)
            // verify required parameter 'xImxEthSignature' is not null or undefined
            assertParamExists('createOrder', 'xImxEthSignature', xImxEthSignature)
            // verify required parameter 'createOrderRequest' is not null or undefined
            assertParamExists('createOrder', 'createOrderRequest', createOrderRequest)
            const localVarPath = `/v1/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xImxEthAddress != null) {
                localVarHeaderParameter['x-imx-eth-address'] = String(xImxEthAddress);
            }

            if (xImxEthSignature != null) {
                localVarHeaderParameter['x-imx-eth-signature'] = String(xImxEthSignature);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get details of an order with the given ID
         * @summary Get details of an order with the given ID
         * @param {string} id Order ID
         * @param {boolean} [includeFees] Set flag to true to include fee body for the order
         * @param {string} [auxiliaryFeePercentages] Comma separated string of fee percentages that are to be paired with auxiliary_fee_recipients
         * @param {string} [auxiliaryFeeRecipients] Comma separated string of fee recipients that are to be paired with auxiliary_fee_percentages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder: async (id: string, includeFees?: boolean, auxiliaryFeePercentages?: string, auxiliaryFeeRecipients?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrder', 'id', id)
            const localVarPath = `/v1/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeFees !== undefined) {
                localVarQueryParameter['include_fees'] = includeFees;
            }

            if (auxiliaryFeePercentages !== undefined) {
                localVarQueryParameter['auxiliary_fee_percentages'] = auxiliaryFeePercentages;
            }

            if (auxiliaryFeeRecipients !== undefined) {
                localVarQueryParameter['auxiliary_fee_recipients'] = auxiliaryFeeRecipients;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get details a signable cancel order
         * @summary Get details a signable cancel order
         * @param {GetSignableCancelOrderRequest} getSignableCancelOrderRequest get a signable cancel order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignableCancelOrder: async (getSignableCancelOrderRequest: GetSignableCancelOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getSignableCancelOrderRequest' is not null or undefined
            assertParamExists('getSignableCancelOrder', 'getSignableCancelOrderRequest', getSignableCancelOrderRequest)
            const localVarPath = `/v1/signable-cancel-order-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getSignableCancelOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a signable order request (V3)
         * @summary Get a signable order request (V3)
         * @param {GetSignableOrderRequest} getSignableOrderRequestV3 get a signable order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignableOrder: async (getSignableOrderRequestV3: GetSignableOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getSignableOrderRequestV3' is not null or undefined
            assertParamExists('getSignableOrder', 'getSignableOrderRequestV3', getSignableOrderRequestV3)
            const localVarPath = `/v3/signable-order-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getSignableOrderRequestV3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of orders
         * @summary Get a list of orders
         * @param {number} [pageSize] Page size of the result
         * @param {string} [cursor] Cursor
         * @param {'created_at' | 'expired_at' | 'sell_quantity' | 'buy_quantity' | 'buy_quantity_with_fees' | 'updated_at'} [orderBy] Property to sort by
         * @param {string} [direction] Direction to sort (asc/desc)
         * @param {string} [user] Ethereum address of the user who submitted this order
         * @param {'active' | 'filled' | 'cancelled' | 'expired' | 'inactive'} [status] Status of this order
         * @param {string} [minTimestamp] Minimum created at timestamp for this order, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [maxTimestamp] Maximum created at timestamp for this order, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [updatedMinTimestamp] Minimum updated at timestamp for this order, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [updatedMaxTimestamp] Maximum updated at timestamp for this order, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [buyTokenType] Token type of the asset this order buys
         * @param {string} [buyTokenId] ERC721 Token ID of the asset this order buys
         * @param {string} [buyAssetId] Internal IMX ID of the asset this order buys
         * @param {string} [buyTokenAddress] Token address of the asset this order buys
         * @param {string} [buyTokenName] Token name of the asset this order buys
         * @param {string} [buyMinQuantity] Min quantity for the asset this order buys
         * @param {string} [buyMaxQuantity] Max quantity for the asset this order buys
         * @param {string} [buyMetadata] JSON-encoded metadata filters for the asset this order buys
         * @param {string} [sellTokenType] Token type of the asset this order sells
         * @param {string} [sellTokenId] ERC721 Token ID of the asset this order sells
         * @param {string} [sellAssetId] Internal IMX ID of the asset this order sells
         * @param {string} [sellTokenAddress] Token address of the asset this order sells
         * @param {string} [sellTokenName] Token name of the asset this order sells
         * @param {string} [sellMinQuantity] Min quantity for the asset this order sells
         * @param {string} [sellMaxQuantity] Max quantity for the asset this order sells
         * @param {string} [sellMetadata] JSON-encoded metadata filters for the asset this order sells
         * @param {string} [auxiliaryFeePercentages] Comma separated string of fee percentages that are to be paired with auxiliary_fee_recipients
         * @param {string} [auxiliaryFeeRecipients] Comma separated string of fee recipients that are to be paired with auxiliary_fee_percentages
         * @param {boolean} [includeFees] Set flag to true to include fee object for orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrders: async (pageSize?: number, cursor?: string, orderBy?: 'created_at' | 'expired_at' | 'sell_quantity' | 'buy_quantity' | 'buy_quantity_with_fees' | 'updated_at', direction?: string, user?: string, status?: 'active' | 'filled' | 'cancelled' | 'expired' | 'inactive', minTimestamp?: string, maxTimestamp?: string, updatedMinTimestamp?: string, updatedMaxTimestamp?: string, buyTokenType?: string, buyTokenId?: string, buyAssetId?: string, buyTokenAddress?: string, buyTokenName?: string, buyMinQuantity?: string, buyMaxQuantity?: string, buyMetadata?: string, sellTokenType?: string, sellTokenId?: string, sellAssetId?: string, sellTokenAddress?: string, sellTokenName?: string, sellMinQuantity?: string, sellMaxQuantity?: string, sellMetadata?: string, auxiliaryFeePercentages?: string, auxiliaryFeeRecipients?: string, includeFees?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (minTimestamp !== undefined) {
                localVarQueryParameter['min_timestamp'] = minTimestamp;
            }

            if (maxTimestamp !== undefined) {
                localVarQueryParameter['max_timestamp'] = maxTimestamp;
            }

            if (updatedMinTimestamp !== undefined) {
                localVarQueryParameter['updated_min_timestamp'] = updatedMinTimestamp;
            }

            if (updatedMaxTimestamp !== undefined) {
                localVarQueryParameter['updated_max_timestamp'] = updatedMaxTimestamp;
            }

            if (buyTokenType !== undefined) {
                localVarQueryParameter['buy_token_type'] = buyTokenType;
            }

            if (buyTokenId !== undefined) {
                localVarQueryParameter['buy_token_id'] = buyTokenId;
            }

            if (buyAssetId !== undefined) {
                localVarQueryParameter['buy_asset_id'] = buyAssetId;
            }

            if (buyTokenAddress !== undefined) {
                localVarQueryParameter['buy_token_address'] = buyTokenAddress;
            }

            if (buyTokenName !== undefined) {
                localVarQueryParameter['buy_token_name'] = buyTokenName;
            }

            if (buyMinQuantity !== undefined) {
                localVarQueryParameter['buy_min_quantity'] = buyMinQuantity;
            }

            if (buyMaxQuantity !== undefined) {
                localVarQueryParameter['buy_max_quantity'] = buyMaxQuantity;
            }

            if (buyMetadata !== undefined) {
                localVarQueryParameter['buy_metadata'] = buyMetadata;
            }

            if (sellTokenType !== undefined) {
                localVarQueryParameter['sell_token_type'] = sellTokenType;
            }

            if (sellTokenId !== undefined) {
                localVarQueryParameter['sell_token_id'] = sellTokenId;
            }

            if (sellAssetId !== undefined) {
                localVarQueryParameter['sell_asset_id'] = sellAssetId;
            }

            if (sellTokenAddress !== undefined) {
                localVarQueryParameter['sell_token_address'] = sellTokenAddress;
            }

            if (sellTokenName !== undefined) {
                localVarQueryParameter['sell_token_name'] = sellTokenName;
            }

            if (sellMinQuantity !== undefined) {
                localVarQueryParameter['sell_min_quantity'] = sellMinQuantity;
            }

            if (sellMaxQuantity !== undefined) {
                localVarQueryParameter['sell_max_quantity'] = sellMaxQuantity;
            }

            if (sellMetadata !== undefined) {
                localVarQueryParameter['sell_metadata'] = sellMetadata;
            }

            if (auxiliaryFeePercentages !== undefined) {
                localVarQueryParameter['auxiliary_fee_percentages'] = auxiliaryFeePercentages;
            }

            if (auxiliaryFeeRecipients !== undefined) {
                localVarQueryParameter['auxiliary_fee_recipients'] = auxiliaryFeeRecipients;
            }

            if (includeFees !== undefined) {
                localVarQueryParameter['include_fees'] = includeFees;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * Cancel an order
         * @summary Cancel an order
         * @param {string} xImxEthAddress eth address
         * @param {string} xImxEthSignature eth signature
         * @param {string} id Order ID to cancel
         * @param {CancelOrderRequest} cancelOrderRequest cancel an order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelOrder(xImxEthAddress: string, xImxEthSignature: string, id: string, cancelOrderRequest: CancelOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelOrder(xImxEthAddress, xImxEthSignature, id, cancelOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create an order
         * @summary Create an order
         * @param {string} xImxEthAddress eth address
         * @param {string} xImxEthSignature eth signature
         * @param {CreateOrderRequest} createOrderRequest create an order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrder(xImxEthAddress: string, xImxEthSignature: string, createOrderRequest: CreateOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrder(xImxEthAddress, xImxEthSignature, createOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get details of an order with the given ID
         * @summary Get details of an order with the given ID
         * @param {string} id Order ID
         * @param {boolean} [includeFees] Set flag to true to include fee body for the order
         * @param {string} [auxiliaryFeePercentages] Comma separated string of fee percentages that are to be paired with auxiliary_fee_recipients
         * @param {string} [auxiliaryFeeRecipients] Comma separated string of fee recipients that are to be paired with auxiliary_fee_percentages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrder(id: string, includeFees?: boolean, auxiliaryFeePercentages?: string, auxiliaryFeeRecipients?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrder(id, includeFees, auxiliaryFeePercentages, auxiliaryFeeRecipients, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get details a signable cancel order
         * @summary Get details a signable cancel order
         * @param {GetSignableCancelOrderRequest} getSignableCancelOrderRequest get a signable cancel order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignableCancelOrder(getSignableCancelOrderRequest: GetSignableCancelOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSignableCancelOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignableCancelOrder(getSignableCancelOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a signable order request (V3)
         * @summary Get a signable order request (V3)
         * @param {GetSignableOrderRequest} getSignableOrderRequestV3 get a signable order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignableOrder(getSignableOrderRequestV3: GetSignableOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSignableOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignableOrder(getSignableOrderRequestV3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of orders
         * @summary Get a list of orders
         * @param {number} [pageSize] Page size of the result
         * @param {string} [cursor] Cursor
         * @param {'created_at' | 'expired_at' | 'sell_quantity' | 'buy_quantity' | 'buy_quantity_with_fees' | 'updated_at'} [orderBy] Property to sort by
         * @param {string} [direction] Direction to sort (asc/desc)
         * @param {string} [user] Ethereum address of the user who submitted this order
         * @param {'active' | 'filled' | 'cancelled' | 'expired' | 'inactive'} [status] Status of this order
         * @param {string} [minTimestamp] Minimum created at timestamp for this order, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [maxTimestamp] Maximum created at timestamp for this order, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [updatedMinTimestamp] Minimum updated at timestamp for this order, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [updatedMaxTimestamp] Maximum updated at timestamp for this order, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [buyTokenType] Token type of the asset this order buys
         * @param {string} [buyTokenId] ERC721 Token ID of the asset this order buys
         * @param {string} [buyAssetId] Internal IMX ID of the asset this order buys
         * @param {string} [buyTokenAddress] Token address of the asset this order buys
         * @param {string} [buyTokenName] Token name of the asset this order buys
         * @param {string} [buyMinQuantity] Min quantity for the asset this order buys
         * @param {string} [buyMaxQuantity] Max quantity for the asset this order buys
         * @param {string} [buyMetadata] JSON-encoded metadata filters for the asset this order buys
         * @param {string} [sellTokenType] Token type of the asset this order sells
         * @param {string} [sellTokenId] ERC721 Token ID of the asset this order sells
         * @param {string} [sellAssetId] Internal IMX ID of the asset this order sells
         * @param {string} [sellTokenAddress] Token address of the asset this order sells
         * @param {string} [sellTokenName] Token name of the asset this order sells
         * @param {string} [sellMinQuantity] Min quantity for the asset this order sells
         * @param {string} [sellMaxQuantity] Max quantity for the asset this order sells
         * @param {string} [sellMetadata] JSON-encoded metadata filters for the asset this order sells
         * @param {string} [auxiliaryFeePercentages] Comma separated string of fee percentages that are to be paired with auxiliary_fee_recipients
         * @param {string} [auxiliaryFeeRecipients] Comma separated string of fee recipients that are to be paired with auxiliary_fee_percentages
         * @param {boolean} [includeFees] Set flag to true to include fee object for orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrders(pageSize?: number, cursor?: string, orderBy?: 'created_at' | 'expired_at' | 'sell_quantity' | 'buy_quantity' | 'buy_quantity_with_fees' | 'updated_at', direction?: string, user?: string, status?: 'active' | 'filled' | 'cancelled' | 'expired' | 'inactive', minTimestamp?: string, maxTimestamp?: string, updatedMinTimestamp?: string, updatedMaxTimestamp?: string, buyTokenType?: string, buyTokenId?: string, buyAssetId?: string, buyTokenAddress?: string, buyTokenName?: string, buyMinQuantity?: string, buyMaxQuantity?: string, buyMetadata?: string, sellTokenType?: string, sellTokenId?: string, sellAssetId?: string, sellTokenAddress?: string, sellTokenName?: string, sellMinQuantity?: string, sellMaxQuantity?: string, sellMetadata?: string, auxiliaryFeePercentages?: string, auxiliaryFeeRecipients?: string, includeFees?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListOrdersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOrders(pageSize, cursor, orderBy, direction, user, status, minTimestamp, maxTimestamp, updatedMinTimestamp, updatedMaxTimestamp, buyTokenType, buyTokenId, buyAssetId, buyTokenAddress, buyTokenName, buyMinQuantity, buyMaxQuantity, buyMetadata, sellTokenType, sellTokenId, sellAssetId, sellTokenAddress, sellTokenName, sellMinQuantity, sellMaxQuantity, sellMetadata, auxiliaryFeePercentages, auxiliaryFeeRecipients, includeFees, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * Cancel an order
         * @summary Cancel an order
         * @param {string} xImxEthAddress eth address
         * @param {string} xImxEthSignature eth signature
         * @param {string} id Order ID to cancel
         * @param {CancelOrderRequest} cancelOrderRequest cancel an order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrder(xImxEthAddress: string, xImxEthSignature: string, id: string, cancelOrderRequest: CancelOrderRequest, options?: any): AxiosPromise<CancelOrderResponse> {
            return localVarFp.cancelOrder(xImxEthAddress, xImxEthSignature, id, cancelOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an order
         * @summary Create an order
         * @param {string} xImxEthAddress eth address
         * @param {string} xImxEthSignature eth signature
         * @param {CreateOrderRequest} createOrderRequest create an order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(xImxEthAddress: string, xImxEthSignature: string, createOrderRequest: CreateOrderRequest, options?: any): AxiosPromise<CreateOrderResponse> {
            return localVarFp.createOrder(xImxEthAddress, xImxEthSignature, createOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get details of an order with the given ID
         * @summary Get details of an order with the given ID
         * @param {string} id Order ID
         * @param {boolean} [includeFees] Set flag to true to include fee body for the order
         * @param {string} [auxiliaryFeePercentages] Comma separated string of fee percentages that are to be paired with auxiliary_fee_recipients
         * @param {string} [auxiliaryFeeRecipients] Comma separated string of fee recipients that are to be paired with auxiliary_fee_percentages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder(id: string, includeFees?: boolean, auxiliaryFeePercentages?: string, auxiliaryFeeRecipients?: string, options?: any): AxiosPromise<Order> {
            return localVarFp.getOrder(id, includeFees, auxiliaryFeePercentages, auxiliaryFeeRecipients, options).then((request) => request(axios, basePath));
        },
        /**
         * Get details a signable cancel order
         * @summary Get details a signable cancel order
         * @param {GetSignableCancelOrderRequest} getSignableCancelOrderRequest get a signable cancel order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignableCancelOrder(getSignableCancelOrderRequest: GetSignableCancelOrderRequest, options?: any): AxiosPromise<GetSignableCancelOrderResponse> {
            return localVarFp.getSignableCancelOrder(getSignableCancelOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a signable order request (V3)
         * @summary Get a signable order request (V3)
         * @param {GetSignableOrderRequest} getSignableOrderRequestV3 get a signable order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignableOrder(getSignableOrderRequestV3: GetSignableOrderRequest, options?: any): AxiosPromise<GetSignableOrderResponse> {
            return localVarFp.getSignableOrder(getSignableOrderRequestV3, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of orders
         * @summary Get a list of orders
         * @param {number} [pageSize] Page size of the result
         * @param {string} [cursor] Cursor
         * @param {'created_at' | 'expired_at' | 'sell_quantity' | 'buy_quantity' | 'buy_quantity_with_fees' | 'updated_at'} [orderBy] Property to sort by
         * @param {string} [direction] Direction to sort (asc/desc)
         * @param {string} [user] Ethereum address of the user who submitted this order
         * @param {'active' | 'filled' | 'cancelled' | 'expired' | 'inactive'} [status] Status of this order
         * @param {string} [minTimestamp] Minimum created at timestamp for this order, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [maxTimestamp] Maximum created at timestamp for this order, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [updatedMinTimestamp] Minimum updated at timestamp for this order, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [updatedMaxTimestamp] Maximum updated at timestamp for this order, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
         * @param {string} [buyTokenType] Token type of the asset this order buys
         * @param {string} [buyTokenId] ERC721 Token ID of the asset this order buys
         * @param {string} [buyAssetId] Internal IMX ID of the asset this order buys
         * @param {string} [buyTokenAddress] Token address of the asset this order buys
         * @param {string} [buyTokenName] Token name of the asset this order buys
         * @param {string} [buyMinQuantity] Min quantity for the asset this order buys
         * @param {string} [buyMaxQuantity] Max quantity for the asset this order buys
         * @param {string} [buyMetadata] JSON-encoded metadata filters for the asset this order buys
         * @param {string} [sellTokenType] Token type of the asset this order sells
         * @param {string} [sellTokenId] ERC721 Token ID of the asset this order sells
         * @param {string} [sellAssetId] Internal IMX ID of the asset this order sells
         * @param {string} [sellTokenAddress] Token address of the asset this order sells
         * @param {string} [sellTokenName] Token name of the asset this order sells
         * @param {string} [sellMinQuantity] Min quantity for the asset this order sells
         * @param {string} [sellMaxQuantity] Max quantity for the asset this order sells
         * @param {string} [sellMetadata] JSON-encoded metadata filters for the asset this order sells
         * @param {string} [auxiliaryFeePercentages] Comma separated string of fee percentages that are to be paired with auxiliary_fee_recipients
         * @param {string} [auxiliaryFeeRecipients] Comma separated string of fee recipients that are to be paired with auxiliary_fee_percentages
         * @param {boolean} [includeFees] Set flag to true to include fee object for orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrders(pageSize?: number, cursor?: string, orderBy?: 'created_at' | 'expired_at' | 'sell_quantity' | 'buy_quantity' | 'buy_quantity_with_fees' | 'updated_at', direction?: string, user?: string, status?: 'active' | 'filled' | 'cancelled' | 'expired' | 'inactive', minTimestamp?: string, maxTimestamp?: string, updatedMinTimestamp?: string, updatedMaxTimestamp?: string, buyTokenType?: string, buyTokenId?: string, buyAssetId?: string, buyTokenAddress?: string, buyTokenName?: string, buyMinQuantity?: string, buyMaxQuantity?: string, buyMetadata?: string, sellTokenType?: string, sellTokenId?: string, sellAssetId?: string, sellTokenAddress?: string, sellTokenName?: string, sellMinQuantity?: string, sellMaxQuantity?: string, sellMetadata?: string, auxiliaryFeePercentages?: string, auxiliaryFeeRecipients?: string, includeFees?: boolean, options?: any): AxiosPromise<ListOrdersResponse> {
            return localVarFp.listOrders(pageSize, cursor, orderBy, direction, user, status, minTimestamp, maxTimestamp, updatedMinTimestamp, updatedMaxTimestamp, buyTokenType, buyTokenId, buyAssetId, buyTokenAddress, buyTokenName, buyMinQuantity, buyMaxQuantity, buyMetadata, sellTokenType, sellTokenId, sellAssetId, sellTokenAddress, sellTokenName, sellMinQuantity, sellMaxQuantity, sellMetadata, auxiliaryFeePercentages, auxiliaryFeeRecipients, includeFees, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cancelOrder operation in OrdersApi.
 * @export
 * @interface OrdersApiCancelOrderRequest
 */
export interface OrdersApiCancelOrderRequest {
    /**
     * eth address
     * @type {string}
     * @memberof OrdersApiCancelOrder
     */
    readonly xImxEthAddress: string

    /**
     * eth signature
     * @type {string}
     * @memberof OrdersApiCancelOrder
     */
    readonly xImxEthSignature: string

    /**
     * Order ID to cancel
     * @type {string}
     * @memberof OrdersApiCancelOrder
     */
    readonly id: string

    /**
     * cancel an order
     * @type {CancelOrderRequest}
     * @memberof OrdersApiCancelOrder
     */
    readonly cancelOrderRequest: CancelOrderRequest
}

/**
 * Request parameters for createOrder operation in OrdersApi.
 * @export
 * @interface OrdersApiCreateOrderRequest
 */
export interface OrdersApiCreateOrderRequest {
    /**
     * eth address
     * @type {string}
     * @memberof OrdersApiCreateOrder
     */
    readonly xImxEthAddress: string

    /**
     * eth signature
     * @type {string}
     * @memberof OrdersApiCreateOrder
     */
    readonly xImxEthSignature: string

    /**
     * create an order
     * @type {CreateOrderRequest}
     * @memberof OrdersApiCreateOrder
     */
    readonly createOrderRequest: CreateOrderRequest
}

/**
 * Request parameters for getOrder operation in OrdersApi.
 * @export
 * @interface OrdersApiGetOrderRequest
 */
export interface OrdersApiGetOrderRequest {
    /**
     * Order ID
     * @type {string}
     * @memberof OrdersApiGetOrder
     */
    readonly id: string

    /**
     * Set flag to true to include fee body for the order
     * @type {boolean}
     * @memberof OrdersApiGetOrder
     */
    readonly includeFees?: boolean

    /**
     * Comma separated string of fee percentages that are to be paired with auxiliary_fee_recipients
     * @type {string}
     * @memberof OrdersApiGetOrder
     */
    readonly auxiliaryFeePercentages?: string

    /**
     * Comma separated string of fee recipients that are to be paired with auxiliary_fee_percentages
     * @type {string}
     * @memberof OrdersApiGetOrder
     */
    readonly auxiliaryFeeRecipients?: string
}

/**
 * Request parameters for getSignableCancelOrder operation in OrdersApi.
 * @export
 * @interface OrdersApiGetSignableCancelOrderRequest
 */
export interface OrdersApiGetSignableCancelOrderRequest {
    /**
     * get a signable cancel order
     * @type {GetSignableCancelOrderRequest}
     * @memberof OrdersApiGetSignableCancelOrder
     */
    readonly getSignableCancelOrderRequest: GetSignableCancelOrderRequest
}

/**
 * Request parameters for getSignableOrder operation in OrdersApi.
 * @export
 * @interface OrdersApiGetSignableOrderRequest
 */
export interface OrdersApiGetSignableOrderRequest {
    /**
     * get a signable order
     * @type {GetSignableOrderRequest}
     * @memberof OrdersApiGetSignableOrder
     */
    readonly getSignableOrderRequestV3: GetSignableOrderRequest
}

/**
 * Request parameters for listOrders operation in OrdersApi.
 * @export
 * @interface OrdersApiListOrdersRequest
 */
export interface OrdersApiListOrdersRequest {
    /**
     * Page size of the result
     * @type {number}
     * @memberof OrdersApiListOrders
     */
    readonly pageSize?: number

    /**
     * Cursor
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly cursor?: string

    /**
     * Property to sort by
     * @type {'created_at' | 'expired_at' | 'sell_quantity' | 'buy_quantity' | 'buy_quantity_with_fees' | 'updated_at'}
     * @memberof OrdersApiListOrders
     */
    readonly orderBy?: 'created_at' | 'expired_at' | 'sell_quantity' | 'buy_quantity' | 'buy_quantity_with_fees' | 'updated_at'

    /**
     * Direction to sort (asc/desc)
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly direction?: string

    /**
     * Ethereum address of the user who submitted this order
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly user?: string

    /**
     * Status of this order
     * @type {'active' | 'filled' | 'cancelled' | 'expired' | 'inactive'}
     * @memberof OrdersApiListOrders
     */
    readonly status?: 'active' | 'filled' | 'cancelled' | 'expired' | 'inactive'

    /**
     * Minimum created at timestamp for this order, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly minTimestamp?: string

    /**
     * Maximum created at timestamp for this order, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly maxTimestamp?: string

    /**
     * Minimum updated at timestamp for this order, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly updatedMinTimestamp?: string

    /**
     * Maximum updated at timestamp for this order, in ISO 8601 UTC format. Example: \&#39;2022-05-27T00:10:22Z\&#39;
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly updatedMaxTimestamp?: string

    /**
     * Token type of the asset this order buys
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly buyTokenType?: string

    /**
     * ERC721 Token ID of the asset this order buys
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly buyTokenId?: string

    /**
     * Internal IMX ID of the asset this order buys
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly buyAssetId?: string

    /**
     * Token address of the asset this order buys
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly buyTokenAddress?: string

    /**
     * Token name of the asset this order buys
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly buyTokenName?: string

    /**
     * Min quantity for the asset this order buys
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly buyMinQuantity?: string

    /**
     * Max quantity for the asset this order buys
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly buyMaxQuantity?: string

    /**
     * JSON-encoded metadata filters for the asset this order buys
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly buyMetadata?: string

    /**
     * Token type of the asset this order sells
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly sellTokenType?: string

    /**
     * ERC721 Token ID of the asset this order sells
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly sellTokenId?: string

    /**
     * Internal IMX ID of the asset this order sells
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly sellAssetId?: string

    /**
     * Token address of the asset this order sells
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly sellTokenAddress?: string

    /**
     * Token name of the asset this order sells
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly sellTokenName?: string

    /**
     * Min quantity for the asset this order sells
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly sellMinQuantity?: string

    /**
     * Max quantity for the asset this order sells
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly sellMaxQuantity?: string

    /**
     * JSON-encoded metadata filters for the asset this order sells
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly sellMetadata?: string

    /**
     * Comma separated string of fee percentages that are to be paired with auxiliary_fee_recipients
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly auxiliaryFeePercentages?: string

    /**
     * Comma separated string of fee recipients that are to be paired with auxiliary_fee_percentages
     * @type {string}
     * @memberof OrdersApiListOrders
     */
    readonly auxiliaryFeeRecipients?: string

    /**
     * Set flag to true to include fee object for orders
     * @type {boolean}
     * @memberof OrdersApiListOrders
     */
    readonly includeFees?: boolean
}

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * Cancel an order
     * @summary Cancel an order
     * @param {OrdersApiCancelOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public cancelOrder(requestParameters: OrdersApiCancelOrderRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).cancelOrder(requestParameters.xImxEthAddress, requestParameters.xImxEthSignature, requestParameters.id, requestParameters.cancelOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an order
     * @summary Create an order
     * @param {OrdersApiCreateOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public createOrder(requestParameters: OrdersApiCreateOrderRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).createOrder(requestParameters.xImxEthAddress, requestParameters.xImxEthSignature, requestParameters.createOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get details of an order with the given ID
     * @summary Get details of an order with the given ID
     * @param {OrdersApiGetOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getOrder(requestParameters: OrdersApiGetOrderRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getOrder(requestParameters.id, requestParameters.includeFees, requestParameters.auxiliaryFeePercentages, requestParameters.auxiliaryFeeRecipients, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get details a signable cancel order
     * @summary Get details a signable cancel order
     * @param {OrdersApiGetSignableCancelOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getSignableCancelOrder(requestParameters: OrdersApiGetSignableCancelOrderRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getSignableCancelOrder(requestParameters.getSignableCancelOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a signable order request (V3)
     * @summary Get a signable order request (V3)
     * @param {OrdersApiGetSignableOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getSignableOrder(requestParameters: OrdersApiGetSignableOrderRequest, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getSignableOrder(requestParameters.getSignableOrderRequestV3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of orders
     * @summary Get a list of orders
     * @param {OrdersApiListOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public listOrders(requestParameters: OrdersApiListOrdersRequest = {}, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).listOrders(requestParameters.pageSize, requestParameters.cursor, requestParameters.orderBy, requestParameters.direction, requestParameters.user, requestParameters.status, requestParameters.minTimestamp, requestParameters.maxTimestamp, requestParameters.updatedMinTimestamp, requestParameters.updatedMaxTimestamp, requestParameters.buyTokenType, requestParameters.buyTokenId, requestParameters.buyAssetId, requestParameters.buyTokenAddress, requestParameters.buyTokenName, requestParameters.buyMinQuantity, requestParameters.buyMaxQuantity, requestParameters.buyMetadata, requestParameters.sellTokenType, requestParameters.sellTokenId, requestParameters.sellAssetId, requestParameters.sellTokenAddress, requestParameters.sellTokenName, requestParameters.sellMinQuantity, requestParameters.sellMaxQuantity, requestParameters.sellMetadata, requestParameters.auxiliaryFeePercentages, requestParameters.auxiliaryFeeRecipients, requestParameters.includeFees, options).then((request) => request(this.axios, this.basePath));
    }
}
